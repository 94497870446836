<template>
  <div id="nav">
    
    <v-toolbar dense>

      <v-toolbar-title>Title</v-toolbar-title>

      <v-spacer></v-spacer>

      <router-link to="/home">Home 2</router-link> |
      <router-link to="/about">About 2</router-link>

    </v-toolbar>
    
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>